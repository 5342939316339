import { useEffect, useState } from 'react';
import './App.css';
import { TaskCard } from './Components/taskcard';
import { TaskUpload } from './Components/taskupload';
import { TaskTable } from './utils/Database';



function App() {

  let taskNumber = 1;

  const [records, setRecords] = useState([]);

  useEffect(() => {
    TaskTable.select({}).eachPage((newRecords, next) => {
      setRecords(newRecords);
      console.log("Fetched Records");
    });

    console.log("Fetching Records");
  }, []);


  if (records.length == 0) {
    return (

      <div className="App">
        <p>Loading....</p>
      </div>
    )
  }

  // is this the single Task Code view? Or the grid view?
  // EXAMPLE URL http://localhost:3000/?TaskCode=ABC123
  const url = new URL(window.location.href);
  if (url.searchParams.has('TaskCode')) {
    return (
      <div className="App">
        <TaskUpload records={records} taskCode={url.searchParams.get('TaskCode')}></TaskUpload>
      </div>
    )
  }



  return (
    <div className="App">
      {/* <header className="App-header">
        <p> JohnIsOldAsFuck.com </p>

      </header> */}

      <div class="flex-table">
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
        <div class="flex-row">
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
          <div class="flex-cell"><TaskCard records={records} taskNumber={taskNumber++}></TaskCard></div>
        </div>
      </div>


    </div>


  );
}

export default App;
