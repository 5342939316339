import Webcam from "react-webcam";
import { useCallback, useRef, useState } from "react"; // import useState
import { S3 } from "aws-sdk";
import { TaskTable } from "../utils/Database";
import { as3 } from "../utils/Credentials";


// const s3 = new S3({credentials: {accessKeyId: 'AKIA2JREKPXTEBEZQZBO', secretAccessKey: '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' }});

const s3 = new S3(as3());

async function uploadImg(taskNumber, airTableRecord, imageData) {
  // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
  const params = {
    Body: imageData,
    Bucket: 'johnisoldasfuck',
    Key: String(taskNumber) + ".jpg",
    ContentType: 'image/jpeg'
  };


  const response = await s3.putObject(params).promise();

  console.log(response);
  console.log("Uploaded image to AWS");

  console.log("Updating airtable record: ");
  console.log(airTableRecord);

  const url = (`https://johnisoldasfuck.s3.amazonaws.com/${String(taskNumber)}.jpg`)


  await TaskTable.update(airTableRecord.id, {Image: url, "Completed": true});
  console.log(response);
}

const CustomWebcam = (props) => {

  const {taskId, airTableRecord} = props;

  const webcamRef = useRef(null); // create a webcam reference
  const [imgSrc, setImgSrc] = useState(null); // initialize it


  const [uploadButtonText, setUploadButtonText] = useState("Mission Accomplished");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  // create a capture function
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);

  }, [webcamRef]);


  const retake = () => {
    setImgSrc(null);
  };

  const saveit = async () => {

    setUploadButtonText("Uploading....");
    setIsButtonDisabled(true);
    // need to convert it from a base64 url encoded img to a blob of image data
    const blob = await fetch(imgSrc).then((res) => res.blob());

    await uploadImg(taskId, airTableRecord, blob);
    
    setUploadButtonText("Finished!");
    window.location.href = '/';
  }


  return (
    <div className="container">
      {imgSrc ? (
        <img src={imgSrc} alt="webcam" />
      ) : (
        <Webcam height={600} width={600} ref={webcamRef} screenshotFormat="image/jpeg" />
      )}
      <div className="btn-container">
        {imgSrc ? (
          <>
          <button onClick={retake}>Retake photo</button>
          <button disabled={isButtonDisabled} onClick={saveit}>{uploadButtonText}</button>
          </>
        ) : (
          <button className="button-fixed"  onClick={capture}>Capture photo</button>
        )}
        <p></p>
        <br></br>
        <p></p>
        <br></br>
      </div>
    </div>
  );
};

export default CustomWebcam;