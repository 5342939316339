// import { useEffect, useState } from "react";
import ModalImage from "react-modal-image";


export function TaskCard(params) {
  let taskNumber = params.taskNumber;
  let allRecords = params.records;

  let ourRecord = allRecords.find((r) => {
    return r.fields.Id == taskNumber;
  });

  let task = ourRecord.fields;

  // console.log(task);


  if (task.Completed === true) {
    return (
      <div class="task-card">

        <ModalImage
          small={task.Image}
          large={task.Image}
        />

        {/* <a href={task.Image} target="_blank">

          <img src={task.Image} />
        </a> */}
      </div>

    )
  }


  return (
    <div class="task-card">
      <div className="large-number ">{task.Id}</div>
      {/* <div className="task-text">{task.TaskCard}</div> */}
    </div>

  )
}