import CustomWebcam from './CustomWebcam';


export function TaskUpload(params) {
    let taskCode = params.taskCode.toUpperCase();
    let allRecords = params.records;
    let ourRecord = allRecords.find((r) => {
        return r.fields.TaskCode == taskCode;
    });

    if (!ourRecord) {
        return (
            <>
                <p>Invalid Task. Stop trying to cheat and go and ask your host for a valid task card</p>

            </>
        )

    }

    let task = ourRecord.fields;

    if (task.Completed) {
        return (
            <>
                <p>This task has already been completed, and here is the evidence!</p>
                <br></br><img src={task.Image}></img>
                <p>Please rip up the card you just used, or roll a joint with it.</p>

            </>
        )
    }


    return (
        <>
          <div class="task-upload">
            <h2 class="task-text">{task.TaskText}</h2>
            {/* <p class="upload-text">(It works best if you take the photo in landscape format)</p> */}
            <CustomWebcam airTableRecord={ourRecord} taskId={task.Id} />
          </div>
        </>
      );


}

